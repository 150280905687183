import { useEffect, useState } from 'react';

export interface AnimationDelay {
  onExpand: number;
  onCollapse: number;
}
interface ExpandAnimationProps {
  children: React.ReactNode;
  expanded: boolean;
  duration?: number;
  delay?: AnimationDelay;
}

export const ExpandAnimation: React.FC<ExpandAnimationProps> = ({
  children,
  expanded,
  delay,
  duration = 250,
}) => {
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setAnimating(true);
    let time = duration;
    if (expanded && delay?.onExpand) {
      time += delay.onExpand;
    } else if (!expanded && delay?.onCollapse) {
      time += delay.onCollapse;
    }
    const timer = setTimeout(() => {
      setAnimating(false);
    }, time);
    return () => {
      clearTimeout(timer);
    };
  }, [duration, expanded, delay]);

  let appliedDelay = '';
  if (expanded && delay?.onExpand) {
    appliedDelay = `${delay.onExpand}ms`;
  } else if (!expanded && delay?.onCollapse) {
    appliedDelay = `${delay.onCollapse}ms`;
  }
  return (
    <div
      className="transition-grid grid"
      style={{
        gridTemplateRows: expanded ? '1fr' : '0fr',
        transitionDelay: appliedDelay || '0ms',
        transitionDuration: `${duration}ms`,
      }}
    >
      <div
        style={{
          overflow: animating || !expanded ? 'hidden' : '',
        }}
      >
        {children}
      </div>
    </div>
  );
};
